//
//  Category Specialist block
//  _____________________________________________

.specialist {
  position: relative;
  z-index: 0;
  &-background {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: calc(55% + 1px);
    background: $secondary__color;
  }

  &-inner {
    @extend .page-main;
    z-index: 1;
    position: relative;
    overflow: hidden;
    height: 310px;
  }
  &-rightcol {
    float: left;
    width: 40%;
  }
  &-leftcol {
    float: left;
    width: 60%;

    img {
      float: left;
    }

    .text-container {
      float: right;
      width: calc(100% - 220px);
      margin-top: 87px;
    }
  }

  &-name {
    @extend .debondt-title;
    font-size: 48px;
    font-weight: 900;
    color: $secondary__color;
    text-align: left;
    position: relative;
    margin: 0;
  }

  &-subtitle {
    @extend .debondt-title;
    font-size: 36px;
    color: #fff;
    text-align: left;
    font-weight: 100;
    margin: -15px 0 0 0;
  }

  &-category-name {
    @extend .debondt-title;
    font-size: 36px;
    color: #fff;
    font-weight: 900;
    text-align: left;
    margin: -15px 0 0 0;
  }

  &-contact {
    font-size: 24px;
    font-weight: 300;
    width: 90%;
    float: right;
    color: #fff;
    margin-top: 150px;

    strong {
      display: block;
      font-weight: 900;
    }
  }
}

//
//  Product Usp's block
//  _____________________________________________

.product-usps {
      float: left;
      width: 36%;
      margin-left: 4%;

      .product-usp-title {
          @extend .debondt-title;
          font-size: 24px;
          font-weight: 900;
          margin-bottom: 20px;
      }

      ul.usp-list {
          margin: 0;
          padding-left: 0;

          li {
              margin-left: 35px;
              position: relative;
              margin-bottom: 10px;
              list-style: none;
              font-style: italic;
              color: $tertiary__color;
              font-size: 14px;

              span {
                  display: block;
                  font-size: 16px;
                  color: $primary__color__darker;
                  font-weight: 900;
                  font-style: normal;
              }

              &:before {
                  font-family: debondt;
                  content: "\e876";
                  color: $primary__color;
                  position: absolute;
                  margin-left: -35px;
                  font-size: 30px;
                  top: -2px;
                  font-style: normal;
              }
          }
      }
  }


  //
  //  Checkout Usp's block
  //  _____________________________________________

  .checkout-cart-usps {
        float: right;
        width: 23%;
        z-index: -1;
        clear: both;

        @include max-screen(1000px) {
          display: none;
        }

        .product-usp-title {
            @extend .debondt-title;
            font-size: 24px;
            margin-bottom: 20px;
        }

        ul.usp-list {
            margin: 0;
            padding-left: 0;
            list-style: none;

            li {
                margin-left: 35px;
                position: relative;
                margin-bottom: 10px;

                font-style: italic;
                color: $tertiary__color;
                font-size: 14px;

                span {
                    display: block;
                    font-size: 16px;
                    color: $primary__color__darker;
                    font-weight: 900;
                    font-style: normal;
                }

                &:before {
                    font-family: debondt;
                    content: "\e876";
                    color: $primary__color;
                    position: absolute;
                    margin-left: -35px;
                    font-size: 30px;
                    top: -2px;
                    font-style: normal;
                }
            }
        }
    }



  //
  //  Checkout Usp's block
  //  _____________________________________________

  .checkout-cart-call-us {
        float: right;
        width: 23%;
        z-index: -1;
        clear: both;
        margin-bottom: 20px;

        @include max-screen(1000px) {
          display: none;
        }

        .product-usp-title {
            @extend .debondt-title;
            font-size: 24px;
            font-weight: 900;
            margin-bottom: 20px;
        }

        p {
          font-size: 14px;
        }
  }

  //
  //  Checkout Usp's block
  //  _____________________________________________


  .header-main-usp {
    text-align: center;
    font-size: 16px;
    background: $quaternary__color;
    color: $primary__color;
    line-height: 38px;
    height: 38px;
    font-weight: 400;
    overflow: hidden;

      a {
          text-decoration: underline;
          color: $primary__color;
      }
}

@include max-screen(1020px) {
  .specialist {

    &-background {

    }

    &-inner {

    }
    &-rightcol {

      width: 30%;
    }
    &-leftcol {

      width: 70%;

      img {

      }

      .text-container {

      }
    }

    &-name {

    }

    &-subtitle {

    }

    &-category-name {

    }

    &-contact {
      font-size: 18px;
      width: 95%;

      strong {

      }
    }
  }
}

@include max-screen(900px) {
  .product-usps {
    width: 100%;
    margin-left: 0;

    .product-usp-title {
      text-align: left;
    }
  }
}

@include max-screen(880px) {
  .specialist {
    &-background {
      bottom: 104px;
      height: calc(41% + 2px);
    }
    &-inner {
      height: 414px;
    }
    &-rightcol {
      width: 100%;
    }
    &-leftcol {
      width: 100%;
    }
    &-contact {
      width: 100%;
      margin: 0;
      font-weight: 400;
      color: $secondary__color;
      padding: 10px;
      font-size: 20px;
      box-sizing: border-box;
      text-align: center;
    }
  }
}

@include max-screen($screen__m) {
  .header-main-usp {
    height: 40px;
    line-height: 40px;
    font-size: 21px;
  }
}

@include max-screen(620px) {
  .specialist {
    &-background {
      bottom: 90px;
      height: calc(33% + 1px);
    }
    &-inner {
      height: 339px;
    }
    &-rightcol {
      width: 100%;
    }
    &-leftcol {
      width: 100%;

      .text-container {
        width: calc(100% - 170px);
      }

      img {
        width: 150px;
      }
    }
    &-contact {
      width: 100%;
      margin: 0;
      font-weight: 400;
      color: $secondary__color;
      padding: 10px;
      font-size: 20px;
      text-align: center;
    }
  }
}

@include max-screen(570px) {
  .specialist {
    &-background {
      display: none;

    }
    &-inner {
      height: auto;
      padding: 0;
      padding-top: 50px;

    }
    &-rightcol {
      width: 100%;
    }
    &-leftcol {
      width: 100%;

      .text-container {
        width: 100%;
        margin-top: 0;
        background: $secondary__color;
        padding: 0 15px;
        box-sizing: border-box;
        text-align: center;
      }

      img {
        display: none;
      }
    }

    &-name {
      margin-top: -50px;
      text-align: center;
    }
    &-subtitle, &-category-name {
      text-align: center;
    }

    &-contact {
      width: 100%;
      margin: 0;
      font-weight: 400;
      color: $secondary__color;
      padding: 10px;
      font-size: 18px;
      text-align: center;
    }
  }
}

@include max-screen(480px) {
  .header-main-usp {
    font-size: 20px;
    height: auto;
  }
}

@include max-screen(420px) {
  .specialist-name {
    font-size: 40px;
    margin-top: -43px;
  }

  .specialist-subtitle {
    font-size: 30px;
    margin-top: -10px;
  }
  .specialist-category-name {
    font-size: 30px;
    margin-top: -5px;
  }
}


@include max-screen(380px) {
  .header-main-usp {
    font-size: 18px;
  }
}

@include max-screen(350px) {
  .header-main-usp {
    font-size: 16px;
  }

  .specialist-name {
    font-size: 36px;
    margin-top: -39px;
  }

  .specialist-subtitle {
    font-size: 28px;
    margin-top: -10px;
  }
  .specialist-category-name {
    font-size: 28px;
    margin-top: -5px;
  }
}

//
//  Checkout Usp's block
//  _____________________________________________

.menu-title {
    @extend .debondt-title;
    font-size: 24px;
    line-height: 1.6;
}

.delivery-speed {
  float: left;
  width: 100%;
  background: $primary__color__lighter;
  padding: 8px 20px;
  text-align: center;
  box-sizing: border-box;
  font-size: 36px;
  font-family: 'Caveat', Arial, Helvetica, sans-serif;
}

.flag {
    margin-right: 5px;
}
